import React from 'react';

export default function ArrowButton(props: any) {
    const { className, style, onClick, extraFunction } = props;
    return (
        <button
            className={className}
            style={{
                ...style,
                display: 'block',
                background: '#EE2B7B',
                color: '#fce3ed',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
            }}
            onClick={() => {
                onClick();
                extraFunction && extraFunction();
            }}
            aria-label="Botón para mover el carrusel"
        >
            <svg
                width="23"
                height="23"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M21.122 15.4729H4.25804C4.12292 15.4771 3.98837 15.4537 3.86265 15.404C3.73694 15.3543 3.62273 15.2794 3.52704 15.1839C3.43155 15.0882 3.35666 14.974 3.30696 14.8483C3.25726 14.7226 3.2338 14.588 3.23803 14.4529C3.2338 14.3178 3.25726 14.1832 3.30696 14.0575C3.35666 13.9318 3.43155 13.8176 3.52704 13.7219C3.62273 13.6264 3.73694 13.5515 3.86265 13.5018C3.98837 13.4521 4.12292 13.4287 4.25804 13.4329H21.122L13.404 5.71491C13.307 5.62307 13.2298 5.51232 13.1772 5.38953C13.1246 5.26674 13.0976 5.13451 13.098 5.00092C13.0976 4.86733 13.1246 4.73504 13.1772 4.61225C13.2298 4.48946 13.307 4.37877 13.404 4.28693C13.4958 4.18986 13.6066 4.11257 13.7294 4.05994C13.8522 4.00731 13.9844 3.98043 14.118 3.9809C14.2516 3.98049 14.3839 4.00744 14.5067 4.06006C14.6295 4.11269 14.7402 4.18991 14.832 4.28693L24.284 13.7389C24.3855 13.835 24.4665 13.9507 24.522 14.0789C24.5684 14.1981 24.5915 14.325 24.59 14.4529C24.5915 14.5808 24.5684 14.7077 24.522 14.8269C24.4664 14.9551 24.3855 15.0708 24.284 15.1669L14.832 24.6189C14.7402 24.7159 14.6295 24.7931 14.5067 24.8458C14.3839 24.8984 14.2516 24.9253 14.118 24.9249C13.9844 24.9253 13.8522 24.8984 13.7294 24.8458C13.6066 24.7931 13.4959 24.7159 13.404 24.6189C13.307 24.5271 13.2297 24.4164 13.1771 24.2936C13.1245 24.1708 13.0976 24.0385 13.098 23.9049C13.0976 23.7713 13.1246 23.639 13.1772 23.5162C13.2298 23.3934 13.307 23.2828 13.404 23.1909L21.122 15.4729Z"
                    fill="white"
                />
            </svg>
        </button>
    );
}
