import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button2 from '../../atoms/button2/button2';
import Carrusel2 from '../carrusel2/carrusel2';
import styles from './stories.module.scss';

export interface IStoriesProps {
    image: string;
    title: string;
    description: any;
    btnTitle: string;
    redirect: string;
    cardsList: [
        {
            image: string;
            title: string;
            description: any;
            bgColor: string;
            textColor: string;
            redirect: string;
            btnTitle: string;
        },
    ];
}

const Stories = ({
    image,
    title,
    description,
    btnTitle,
    redirect,
    cardsList,
}: IStoriesProps) => {
    return (
        <Container component="div" className={`${styles.o_stories} o_stories`}>
            <CardMedia
                component="img"
                className={styles.o_stories__header}
                image={image}
                alt={title}
            />
            <Container component="div" className={styles.o_stories__container}>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        className={styles.o_stories__card}
                    >
                        <Card className={styles.o_stories__main}>
                            <CardContent>
                                <Typography
                                    variant="h2"
                                    component="div"
                                    className={styles.o_stories__h2}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={styles.o_stories__desc}
                                >
                                    {description}
                                </Typography>
                            </CardContent>
                            <CardActions className={styles.o_stories__cta}>
                                <Button2 redirect={redirect} label={btnTitle} classname={styles.o_stories__btn}/>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        className={styles.o_stories__card}
                    >
                        <Carrusel2
                            cardsList={cardsList}
                            slidesToScroll={2}
                            slidesToShow={2}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default Stories;
